import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

let defaultApiUrl = `https://cs-api.${window.location.hostname.split('.').slice(1).join('.')}/`
let defaultAuthUrl = `https://auth.${window.location.hostname.split('.').slice(1).join('.')}/`
let defaultGrafanaUrl = `https://grafana.${window.location.hostname.split('.').slice(1).join('.')}/`

export default new Vuex.Store({
  state: {
    apiUrl: process.env.VUE_APP_API_URL || defaultApiUrl,
    authUrl: process.env.VUE_APP_AUTH_URL || defaultAuthUrl,
    grafanaUrl: defaultGrafanaUrl,
    authenticated: false,
    auth: {
      accessToken: null,
      refreshToken: null,
    },
    user: null,
    meta: {},
  },
  mutations: {
    authenticated (state, value) {
      state.authenticated = value
    },
    auth (state, value) {
      state.auth = value
    },
    meta (state, value) {
      state.meta = value
    },
    user (state, value) {
      state.user = value
    },
  },
  actions: {
    async handleLogin ({dispatch, commit}, data) {
      commit(
        'auth',
        {accessToken: data.access_token, refreshToken: data.refresh_token}
      )
      commit(
        'authenticated',
        true
      )
      commit(
        'user',
        data.user
      )
      await dispatch("refreshMeta")
    },
    async refreshMeta ({commit}) {
      let http = await import('./http.js');
      let response = await http.default.get("admin/meta")
      commit("meta", response.data)
    },
    logout ({commit}) {
      commit('user', null)
      commit('auth', {})
      commit('authenticated', false)
    }
  },
  modules: {
  },
  plugins: [
    new VuexPersistence({
      reducer: (state) => ({
        auth: state.auth,
        authenticated: state.authenticated,
        user: state.user,
      }),
    }).plugin
  ]
})
