import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.filter('truncate', function (text, length, suffix='') {
  text = String(text)
  if (text.length > length) {
    return text.substring(0, length) + suffix
  } else {
    return text
  }
});
Vue.mixin({
  data: function() {
    return {
      get globalAuthUrl() {
        return store.state.authUrl;
      },
      get globalGrafanaUrl() {
        return store.state.grafanaUrl;
      }
    }
  }
})
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')