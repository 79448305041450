<template>
  <v-container>
  </v-container>
     
</template>

<script>
// @ is an alias to /src

export default {
  created () {
    this.$router.push('/communities')
  }
}
</script>
